import React from 'react'
import { graphql } from 'gatsby'
import BlockContent from '../components/block-content'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
// import PeopleGrid from '../components/people-grid'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import { mapEdgesToNodes, filterOutDocsWithoutSlugs } from '../lib/helpers'
import localize from '../components/localize'
import LayoutContainer from '../components/layout-container'
import SanityTextBlockEmbed from '../components/body/SanityTextBlockEmbed'
// import Hero from '../components/body/Hero'
import VideoListing from '../components/youtube/youtube'
import { responsiveTitle1 } from '../components/typography.module.css'
import PreviewGrid from '../components/lists-preview/preview-grid'

export const query = graphql`
  query PageTemplateQuery($id: String!) {
    page: sanityPage(id: { eq: $id }) {
      id
      _type
      _rawBody
      title {
        es
        en
        _type
      }

      slug {
        es {
          current
        }
        en {
          current
        }
        _type
      }
      content {
        ... on SanityAlbumList {
          _key
          _type
          title {
            en
            es
            _type
          }

          albumItem {
            _type

            slug {
              current
            }
            _rawMainImage
            id
            mainImage {
              alt
              asset {
                _id
              }
            }
            title
          }
        }

        ... on SanityVideoListing {
          _rawVideoItem
          _rawTitle
          _type
          title {
            en
            es
            _type
          }
          videoItem {
            url
            _type
          }
        }
      }
      openGraph {
        title
        description
        image {
          asset {
            _id
          }
        }
      }
    }
  }
`

const PageTemplate = ({ data, errors, pageContext }) => {
  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }
  const page = data && data.page

  // console.log(data)
  const locale = pageContext.locale

  const content = (page.content || []).map((item, key) => {
    let el = null
    switch (item._type) {
      case 'textBlockEmbed':
        // console.log('textBlockEmbed', item)
        el = <SanityTextBlockEmbed key={key} blocks={item._rawBody || []} />
        break
      case 'videoListing':
        // console.log('videoListing', item)
        el = <VideoListing {...item} />
        break
      case 'albumList':
        // console.log('"albumList"', item)
        el = (
          <PreviewGrid
            title={item.title}
            nodes={item.albumItem}
            // browseMoreHref='/Services/'
            locale={locale}
          />
        )
        // el = <VideoListing {...item} />
        break
      default:
        el = null
    }
    return el
  })

  if (!page) {
    throw new Error(
      'Missing "About" page data. Open the studio at http://localhost:3333 and add "About" page data and restart the development server.'
    )
  }

  return (
    <LayoutContainer locale={locale}>
      <SEO title={page.title} lang={locale} />
      <Container>
        <h1 className={responsiveTitle1}>{page.title}</h1>
        {content}
        <BlockContent blocks={page._rawBody || []} />
      </Container>
    </LayoutContainer>
  )
}

export default localize(PageTemplate)
